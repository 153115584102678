* {
  box-sizing: border-box;
}

body {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
}

.tab-strip {
  position: fixed;
  top: 0;
  left: 1rem;
  z-index: 1;
  display: flex;
}

.tab-strip__tabs {
  margin-right: 2rem;
}

.button {
  background: none;
  outline: none;
  border: 1px solid transparent;
  border-left-color:  rgb(180, 180, 180);
  padding: 0.3rem 0.4rem;
  /* font-size: 1rem; */
}

.button:hover {
  background: rgb(224, 224, 224);
}

.button--active {
  border-bottom-color: rgb(180, 180, 180);
}
